import React, { useState, useEffect } from 'react';
import { graphql, useStaticQuery, Link } from 'gatsby';
import Img from "gatsby-image";
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { FormattedMessage, useIntl } from 'gatsby-plugin-intl';
import Layout from '../components/layout';

import styles from "../sass/pages/contact.module.scss";

import sent from "../assets/img/sent.svg"


let lang = "";

const Message = (props: any) => {
    // console.log("PROPS", props);

    lang = useIntl().locale;
    // const title = useIntl().formatMessage({ id: 'position' });

    const [load] = useState(0);

    return (
        <Layout color="">
            

            <article className="section-fade space">
            <div className="space"></div>
                <div className={"centered"}>
                    <img src={sent} alt="Message sent" className={styles.icon}/>
                    <div className="space-small"></div>
                    <h1><FormattedMessage id="thank_you"/></h1>
                </div>
            </article>
            <div className="space"></div>
            <div className="space"></div>
            <div className="space"></div>
        </Layout>
    )
}
export default Message;